.scheduledVideos {
}

.imageListItem {
    position: relative;

    .sourceLink {
        position: absolute;
        top: 65%;
        left: 10%;
    }
}
