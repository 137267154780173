.titleBar {
  display: flex;
  align-items: center;
  padding: 0.5em 3em 0em;
}

.smallLogo {
  max-width: 4em;
}

.logo {
  display: flex;
  flex-direction: row;
  align-items: center;
}
