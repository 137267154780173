.loginForm {
  margin: auto;
  width: 50%;
  max-width: 25em;
}

.loginButton {
  display: flex;
  margin: 1em;
}

.loginButton button {
  margin: auto;
}

.textField {
  margin: 0.25em;
}
