.thumbnail {
  max-width: 100%;
  max-height: 10em;
  border-radius: 0.2em;
}

.imageContainer {
  max-height: 80%;
  max-width: 80%;
  margin: auto;
}

.duration {
  text-align: start;
  position: absolute;
  margin-top: -3.5em;
  margin-left: 0.4em;
  background: black;
  border-radius: 0.1em;
  padding: 0.1em;
  color: white;
}

.size {
  text-align: start;
  position: absolute;
  margin-top: -2em;
  margin-left: 0.4em;
  background: black;
  border-radius: 0.1em;
  padding: 0.1em;
  color: white;
}

.videoMetadataCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.videoTitle {
  margin-top: 0.5em;
}
