.videoFilter {
  width: 95%;
  margin: auto;
  display: inline-flex;
}

.left,
.right {
  display: inline-block;
  padding: 0 1em;
}

.left {
  width: 30%;
}

.right {
  width: 60%;
}

.search {
  width: 80%;
  margin-left: 1em;
}

.selectors {
  display: block;
}

.videoSiteSelector,
.sortBy {
  display: inline-block;
  width: 40%;
  margin: 1.3em 1em;
}

.sortBy {
  text-align: start;
}
