.card {
  max-width: 60em;
  padding: 1em 2em;
}

.videoMetadata {
  height: 50%;
}

button {
  &.deleteButton {
    margin-bottom: 1em;
  }
}